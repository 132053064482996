<template>
  <div class="row">
    <div class="col-md-12">
      <h1>Lista użytkowników</h1>
    </div>
  </div>
  <div class="row">
    <!--    <div class="col-md-5">-->
    <form @submit.prevent="displayUserList(1, 10000, userIdentity)" class="form-inline">
      <div class="input-group">
        <div class="col-md-3>">
          <label for="userIdentity">Username or email:</label><br/>
          <input v-model="userIdentity" type="text" class="form-control" id="userIdentity">
        </div>
        <div class="col-md-3>">
          <br/>
          <button type="submit" class="btn btn-primary">Search</button>
        </div>
      </div>
    </form>
    <!--    </div>-->
    <!--    <div class="col-md-7"></div>-->
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <template v-for="index in pages" :key="index">
        <button v-on:click="displayUserList(index)">{{index}}</button>&nbsp;&nbsp;
      </template>
    </div>
    <div class="col-md-2"></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table id="payments" class="list-table">
        <tr>
          <th style="width: 2%;">ID</th>
          <th style="width: 5%;">Username</th>
          <th style="width: 5%;">User email</th>
          <th style="width: 5%;">Roles</th>
          <th style="width: 5%;">Marketing Agreement</th>
          <th style="width: 5%;">Akcja</th>
        </tr>

        <template v-for="user in usersList" v-bind:key="user.id">
          <tr>
            <td>{{ user.id }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>
              <template v-for="role in user.roles" v-bind:key="role">
                <p>{{ role }}</p>
              </template>
            </td>
            <td>{{ user.marketingAgreement }}</td>
            <td><router-link v-bind:to="'/admin/user/' + user.id" tag="button">Wyświetl</router-link></td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <template v-for="index in pages" :key="index">
        <button v-on:click="displayUserList(index)">{{index}}</button>&nbsp;&nbsp;
      </template>
    </div>
    <div class="col-md-2"></div>
  </div>
</template>

<script>
import { UserService } from '../../../services/userService';

export default {
  name: 'PaymentList',
  data() {
    return {
      userIdentity: null,
      salesPackageName: null,
      salesPackageCode: null,
      usersList: [],
      pages: null
    };
  },
  mounted() {
    this.displayUserList();
  },
  methods: {
    async displayUserList(page = 1, limit = 10, userIdentity = null) {
      const response = await UserService.getUserList(page, limit, userIdentity);
      const users = response.data.users;
      this.usersList = users.items;
      const totalCount = users.totalCount;

      this.pages = Math.ceil((totalCount / limit));
    }
  }
};
</script>

<style scoped>
.list-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.list-table td, .list-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.list-table tr:nth-child(even){background-color: #f2f2f2;}

.list-table tr:hover {background-color: #ddd;}

.list-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.fm-link {
  text-decoration: underline;
  color: blue;
}
.pointer {
  cursor: pointer;
}
</style>
